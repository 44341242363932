import React, { useState } from 'react';
import FormParam from './FormParam';
import FormParamContent from './FormParamContent'; // Ensure this is imported
import './FormFlowsEditContent.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FormFlowsEditContent = ({ selectedScreen, onContentChange, onTitleChange, onButtonChange, currentUser }) => {
  const [siblings, setSiblings] = useState([]);
  const [buttonLabel, setButtonLabel] = useState(selectedScreen?.button || ''); // State to manage the button label

  if (!selectedScreen) return null;

  const handleContentChange = (id, updatedContent) => {
    const updatedContents = selectedScreen.content.map(contentItem =>
      contentItem.id === id ? updatedContent : contentItem // Only update the matching item
    );
  
    onContentChange(selectedScreen.id, updatedContents); // Ensure this updates the parent state
  };

  const handleAddContent = (subOption, option) => {
    setSiblings(option);  // Store the sibling options
    const newContentItem = { id: Date.now().toString(), name: subOption.name, type: subOption.id, content: '', isRequired: false };
    const updatedContents = [...selectedScreen.content, newContentItem];
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleDeleteContent = (id) => {
    const updatedContents = selectedScreen.content.filter(contentItem => contentItem.id !== id);
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleTitleChange = (e) => {
    onTitleChange(selectedScreen.id, e.target.value);
  };

  const handleButtonChange = (e) => {
    setButtonLabel(e.target.value); // Update the button label state
    onButtonChange(selectedScreen.id, e.target.value); // Send the updated label back to parent component
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedContents = Array.from(selectedScreen.content);
    const [movedItem] = updatedContents.splice(result.source.index, 1);
    updatedContents.splice(result.destination.index, 0, movedItem);

    onContentChange(selectedScreen.id, updatedContents);
  };
  const handleVariablesUpdate = (screenVariables) => {
    // Handle logic for storing screen variables, like:
    console.log('Screen Variables:', screenVariables);
    // You can store these in the state or pass them to an API
  };
  const getSiblingOptions = (contentItem) => {
    const typeNumber = parseFloat(contentItem.type);

    if (typeNumber >= 1.1 && typeNumber < 2) {
      return [
        { id: 1.1, name: 'Large Heading' , namingType:'TextHeading'},
        { id: 1.2, name: 'Small Heading' ,namingType:'TextSubheading' },
        { id: 1.3, name: 'Caption' , namingType:'TextCaption'},
        { id: 1.4, name: 'Body', namingType: 'TextBody' },
      ];
    } else if (typeNumber >= 2.1 && typeNumber < 3) {
      return [{ id: 2.1, name: 'Image', namingType:'Image' }];
    } else if (typeNumber >= 3.1 && typeNumber < 4) {
      return [
        { id: 3.1, name: 'Short Answer' , namingType:'TextInput'},
        { id: 3.2, name: 'Paragraph', namingType:'TextArea' },
        { id: 3.3, name: 'Date Picker', namingType:'DatePicker' },
      ];
    } else if (typeNumber >= 4.1 && typeNumber < 5) {
      return [
        { id: 4.1, name: 'Single Choice', namingType:'RadioButtonsGroup' },
        { id: 4.2, name: 'Multiple Choice', namingType :'CheckboxGroup'},
        { id: 4.3, name: 'Dropdown' , namingType:'Dropdown'},
      ];
    }
    return [];
  };

  return (
    <div className="FormFlowsEdit-content-section">
      <h2 className='FormFlowsEdit-content-section-h2'>Edit content</h2>
      <input
        type="text"
        placeholder="Screen title"
        value={selectedScreen.title}
        onChange={handleTitleChange}
      />
      <input
        type="text"
        placeholder="Button label"
        value={buttonLabel}
        onChange={handleButtonChange}
      />
      {/* Add input for button label */}
      
      
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="formParamContents">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="droppable-container">
              {selectedScreen.content.map((contentItem, index) => (
                <Draggable key={contentItem.id} draggableId={contentItem.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="content-item"
                    >
                      <FormParamContent
                        contentItem={contentItem}
                        onUpdateContent={handleContentChange}
                        onDeleteContent={handleDeleteContent}
                        siblingOptions={getSiblingOptions(contentItem)} // Get the specific sibling options for this item
                        currentUser={currentUser}
                        onVariablesUpdate={handleVariablesUpdate}

                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      
      <FormParam onAddContent={handleAddContent} />
      
    </div>
    
  );
};

export default FormFlowsEditContent;
