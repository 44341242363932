import React, { useState, useEffect } from 'react';
import './FormFlowsGenerator.css';
import FormFlowsScreens from './FormFlowsScreens';
import FormFlowsEditContent from './FormFlowsEditContent';
import FormFlowsPreview from './FormFlowsPreview';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Assuming you're using Axios

const FormFlowsGenerator = (currentUser) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const isEditing = location.state?.flow ? true :  false;
  console.log(isEditing);
  const flowName = queryParams.get('flowName');
  console.log(flowName);
  const selectedCategory = queryParams.get('selectedCategory');
  console.log(selectedCategory);
  const [sendAllAnswersOnCompletion, setSendAllAnswersOnCompletion] = useState(false);
  console.log(sendAllAnswersOnCompletion);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false); // State for publish confirmation modal

  const [sendAllAnswers, setSendAllAnswers] = useState(false);
  const initialFlow = isEditing ? location.state.flow?.flow : {
    id: Date.now(),
    name: flowName || 'Unnamed Flow',
    category: selectedCategory || 'Uncategorized',
    screens: [{ id: Date.now(), title: 'First Screen', content: [], button: 'Continue' }],
    assets: {
      version: '3.1',
      screens: []
    }
  };

  const [flow, setFlow] = useState(initialFlow);
  const [selectedScreen, setSelectedScreen] = useState(initialFlow.screens[0]);
  const getPayloadForScreen = (screens, currentScreenIndex) => {
    let payload = {};
  
    // Iterate over all previous screens up to the current one
    for (let i = 0; i <= currentScreenIndex; i++) {
      const screen = screens[i];
      const screenIdFormatted = screen.title.replace(/\s+/g, '_').toUpperCase();
  
      screen.content.forEach((contentItem, contentIndex) => {
        const uniqueName = contentItem.name
          ? contentItem.name.replace(/\s+/g, '_').replace(/-/g, '_') + `_S${i}_C${contentIndex}`
          : `field_S${i}_C${contentIndex}`;
  
        // If sendOnChange is true, include in the payload
        if (contentItem.sendOnComplate === true) {
          // For current screen, it comes from the form object
          if (i === currentScreenIndex) {
            payload[uniqueName] = `\${form.${uniqueName}}`;
          } 
          // For previous screens, it comes from screen data
          else {
            payload[`screen_${i}_${uniqueName}`] = `\${screen.${screenIdFormatted}.${uniqueName}}`;
          }
        }
      });
    }
  
    return payload;
  };
  
  const updateAssets = (screens) => {
    const updatedAssets = {
      ...flow.assets,
      screens: screens.map((screen, index) => {
        const screenId = screen.title.replace(/\s+/g, '_').toUpperCase();
        const isLastScreen = index === screens.length - 1;
        
        return {
          id: screenId,
          title: screen.title,
          data: {},
          terminal: isLastScreen,
          layout: {
            type: 'SingleColumnLayout',
            children: [{
              type: 'Form',
              name: 'flow_path',
              children: screen.content.map((contentItem, contentIndex) => mapContentToObject(contentItem, contentIndex, index)).concat({
                type: 'Footer',
                label: screen.button || 'Submit',
                'on-click-action': {
                  name: isLastScreen ? 'complete' : 'navigate',
                  next: !isLastScreen
                    ? {
                        type: 'screen',
                        name: screens[index + 1].title.replace(/\s+/g, '_').toUpperCase(),
                      }
                    : null,
                    payload: getPayloadForScreen(screens, index) // Pass payload for current and previous screens
                  }
              })
            }]
          }
        };
      })
    };

    return updatedAssets;
  };

  const handleAddScreen = () => {
    const newScreen = {
      id: Date.now(),
      title: `Screen ${flow.screens.length + 1}`,
      content: [],
      button: 'Continue'
    };
    const updatedScreens = [...flow.screens, newScreen];
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };

    setFlow(updatedFlow);
    setSelectedScreen(newScreen);
  };

  const handleSelectScreen = (screenId) => {
    setSelectedScreen(flow.screens.find(screen => screen.id === screenId));
  };

  const handleContentChange = (screenId, updatedContent) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, content: updatedContent } : screen
    );

    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleButtonChange = (screenId, updateButton) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, button: updateButton } : screen
    );
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };
    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };
  console.log(flow);
  const handleTitleChange = (screenId, updatedTitle) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, title: updatedTitle } : screen
    );
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };
    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleRemoveScreen = (screenId) => {
    const updatedScreens = flow.screens.filter(screen => screen.id !== screenId);
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.length > 0 ? updatedScreens[0] : null);
  };
  const getDataForNextScreen = (screens, currentScreenIndex) => {
    let accumulatedData = {};
  
    // Collect data from all previous screens up to the current one
    for (let i = 0; i < currentScreenIndex; i++) {
      const screen = screens[i];
      screen.content.forEach((contentItem, contentIndex) => {
        const contentName = contentItem.name
          ? contentItem.name.replace(/\s+/g, '_') + contentIndex
          : `field_${contentIndex}`;
  
        if (contentItem?.sendOnComplate) {
          // Add data in the required format for the next screen
          accumulatedData[contentName] = {
            type: typeof contentItem.content === 'string' ? 'string' : 'array',
            "__example__": contentItem.content || ""
          };
        }
      });
    }
  
    return accumulatedData;
  };
  const generateUniqueName = (type, index) => {
    return `${type}_${index}_${Math.random().toString(36).substr(2, 6)}`;
  };

  const handleSubmit = () => {
    const routingModel = {};

    const Flow = {
      Name: flow.name,
      Version: "4.0",
      DataApiVersion: "3.0",
      RoutingModel: routingModel,
      Screens: flow.screens.map((screen, index) => {
        const isLastScreen = index === flow.screens.length - 1;    
        // Get accumulated data from all previous screens
        const dataForScreen = getDataForNextScreen(flow.screens, index);    
        return {
          id: screen.title.replace(/\s+/g, '_').toUpperCase(),
          title: screen.title,
          terminal: isLastScreen,
          data: dataForScreen, // Include the accumulated data in the 'data' field
          layout: {
            type: "SingleColumnLayout",
            children: screen.content
              .map((contentItem, contentIndex) => mapContentToObject(contentItem, contentIndex, index))
              .concat({
                type: 'Footer',
                label: screen.button,
                'on-click-action': {
                  name: isLastScreen ? 'complete' : 'navigate',
                  next: !isLastScreen
                    ? { type: "screen", name: flow.screens[index + 1]?.title.replace(/\s+/g, '_').toUpperCase() }
                    : null,
                  payload: isLastScreen
                    ? getPayloadForLastScreen(flow.screens) // For the last screen, use a complete payload
                    : getPayloadForScreen(flow.screens, index), // For non-terminal screens, use incremental payload
                },
              }),
          },
        };
      }),
    };

    const formFlowData = {
        flowName: flow.name,
        category: queryParams.get('selectedCategory'),
        sendAllAnswersOnCompletion: sendAllAnswers,
        flow: Flow,
        organization: currentUser.currentUser.organization,
        endpoint: queryParams.get('endpointUrl'),
        WriteDataWebhook: queryParams.get('writeAnswerEndpoint'),
        GetDataWebhook: queryParams.get('getDataEndpoint'),
        endpointType : queryParams.get('endpointType')
    };
    console.log(formFlowData);
    const requestPayload = {
        organization: currentUser.currentUser.organization,
        formFlow: formFlowData
    };

    console.log('Submitting Request Payload:', JSON.stringify(requestPayload, null, 2));

    axios.post('https://gambot.azurewebsites.net/api/webhooks/CreateFormFlow', requestPayload)
      .then(response => {
        console.log('Flow created successfully:', response.data);
        navigate('/FormFlows'); // Use the correct path to your FormFlows page

      })
      .catch(error => {
        console.error('Error creating flow:', error);
      });
};

const getPayloadForLastScreen = (screens) => {
  let payload = {};

  // Iterate over all screens and generate the payload keys
  screens.forEach((screen, screenIndex) => {
    const screenIdFormatted = screen.title.replace(/\s+/g, '_').toUpperCase(); // Format screen ID

    // Check if `screen.content` exists
    if (screen.content) {
      screen.content.forEach((contentItem, contentIndex) => {
        // Generate the same uniqueName format as in the mapContentToObject function
        const contentName = contentItem.name
          ? contentItem.name.replace(/\s+/g, '_').replace(/-/g, '_') + `_S${screenIndex}_C${contentIndex}`
          : `field_S${screenIndex}_C${contentIndex}`;
        
        // Set the payload keys to match the unique name format
        payload[`screen_${screenIndex}_${contentName}`] = `\${screen.${screenIdFormatted}.${contentName}}`;
      });
    } else {
      console.warn(`No content found for screen: ${screen.title}`);
    }
  });

  return payload;
};

  
  const mapContentToObject = (contentItem, contentIndex, screenIndex) => {
    console.log('Screen Index:', screenIndex);
    console.log('Content Index:', contentIndex);

    let contentObject = {};
    const uniqueName = contentItem.name
        ? contentItem.name.replace(/\s+/g, '_').replace(/-/g, '_') + `_S${screenIndex}_C${contentIndex}`
        : `field_S${screenIndex}_C${contentIndex}`;
    console.log(contentItem);
    console.log(contentIndex);
    contentIndex = contentIndex -1 ;
    //const uniqueName = contentItem.name;
    console.log(contentIndex);
    console.log('uniqueName' , uniqueName);    
    // Map content types correctly
    switch (contentItem.type) {
      case 1.1: // Large Heading
        contentObject = { type: 'TextHeading', text: contentItem.content , typeId : 1.1|| '' };
        break;
      case 1.2: // Small Heading
        contentObject = { type: 'TextSubheading', text: contentItem.content , typeId : 1.2|| '' };
        break;
      case 1.3: // Caption
        contentObject = { type: 'TextCaption', text: contentItem.content , typeId : 1.3 || '' };
        break;
      case 1.4: // Body
        contentObject = { type: 'TextBody', text: contentItem.content , typeId : 1.4|| '' };
        break;
      case 2.1: // Image
        contentObject = {
          type: 'Image',
          src: contentItem.content?.src ? `${contentItem.content.src.src}` : '',
          height: contentItem.content?.height || 340,
          'scale-type': contentItem.content?.['scale-type'] || 'contain',
          // required: contentItem.isRequired || false,
          // sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here.
          typeId : 2.1
        };
        break;
      case 3.1: // TextInput
        contentObject = {
          type: 'TextInput',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'input-type': 'text',
          'helper-text': contentItem.instruction || "",
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here   
          typeId : 3.1,
          instruction : contentItem?.instruction || ""
        };
        break;
      case 3.2: // TextArea
        contentObject = {
          type: 'TextArea',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'helper-text': contentItem.instruction || "",
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 3.2,
          instruction : contentItem?.instruction || ""
        };
        break;
      case 3.3: // DatePicker
        contentObject = {
          type: 'DatePicker',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'helper-text': contentItem.instruction || "",
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 3.3,
          instruction : contentItem?.instruction || ""
        };
        break;
      case 4.1: // RadioButtonsGroup
        contentObject = {
          type: 'RadioButtonsGroup',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 4.1
        };
        break;
      case 4.2: // CheckboxGroup
        contentObject = {
          type: 'CheckboxGroup',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 4.2
        };
        break;
      case 4.3: // Dropdown
        contentObject = {
          type: 'Dropdown',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 4.3
        };
        break;
      case 4.4: // OptIn
        contentObject = {
          type: 'OptIn',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          sendOnComplete : contentItem?.sendOnComplate || false,
          name: uniqueName,  // Use the generated unique name here
          typeId : 4.4
        };
        break;
      default:
        contentObject = { type: '', text: '', required: contentItem.isRequired || false };
    }

    return contentObject;
  };

  return (
    <div className="form-flows-generator-container">
      <div className="form-flows-header">
        <div className="flow-name-category">
          <h2>{flow.name} - {flow.category}</h2>
        </div>
        <div className="form-flows-generator-container-save-button-container">
          <button onClick={handleSubmit} className="form-flows-generator-container-save-button">
            Save
          </button>
        </div>
      </div>
      <div className='form-flows-body'>
        <FormFlowsScreens
          flow={flow}
          onAddScreen={handleAddScreen}
          onSelectScreen={handleSelectScreen}
          onRemoveScreen={handleRemoveScreen}
        />
        <FormFlowsEditContent
          selectedScreen={selectedScreen}
          onContentChange={handleContentChange}
          onTitleChange={handleTitleChange}
          onButtonChange={handleButtonChange}
          currentUser={currentUser}
        />
        <FormFlowsPreview selectedScreen={selectedScreen} />
      </div>
    </div>
  );
};

export default FormFlowsGenerator;
