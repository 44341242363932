import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ChatPage from './components/ChatPage';
import { useState, useEffect } from 'react';
import Login from './components/Login';
import { auth } from './firebase';
import Settings from './components/Settings';
import AboutUs from './components/Website/AboutUs';
import NavbarMenu from './components/Website/NavBarMenu';
import HomePage from './components/Website/HomePage';
import Contacts from './components/Contacts';
import Menu from './components/Menu';
import TemplateGenerator from './components/Templates/TemplateGenerator';
import Templates from './components/Templates/Templates';
import NewMessageTemplate from './components/Templates/NewMessageTemplate';
import TemplateUpdate from './components/Templates/TemplateUpdate';
import Integrations from './components/Integrations/Integrations';
import IntegrationsForm from './components/Integrations/IntegrationsForm';
import Botomation from './components/Botomation/Botomation';
import BotomationForm from './components/Botomation/BotomationForm';
import Campaigns from './components/Campaign/Campaigns';
import CampaignForm from './components/Campaign/CampaignForm';
import WebSocketInstance from './components/WebSocketInstance'; // Ensure WebSocketInstance is imported
import FacebookLogin from './components/FacebookLogin';
import FormFlows from './components/FormFlows/FormFlows';
import NewFormFlow from './components/FormFlows/NewFormFlows';
import FormFlowsGenerator from './components/FormFlows/FormFlowsGenerator';
function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [webSocket, setWebSocket] = useState(null);

  useEffect(() => {
    if (user && !webSocket) {
      setWebSocket(new WebSocketInstance(user.organization));
    }
  }, [user, webSocket]);

  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      setWebSocket(null);
      localStorage.removeItem('user');
    }).catch((err) => alert(err.message));
  }

  return (
    <Router>
      <div className="App">
        {user ? (
          <div className="app-container">
            <div className="menu-column">
              <Menu />
            </div>
            <div className="content-column">
              <Routes>
                <Route path='/New-Template' element={<TemplateGenerator />} />
                <Route path='/Templates' element={<Templates currentUser={user} signOut={signOut} />} />
                <Route path='/FormFlows' element={<FormFlows currentUser={user} signOut={signOut} />} />
                <Route path='/Integrations' element={<Integrations currentUser={user} signOut={signOut} />} />
                <Route path='/NewIntegration' element={<IntegrationsForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateIntegration' element={<IntegrationsForm currentUser={user} signOut={signOut} />} />
                <Route path='/Botomations' element={<Botomation currentUser={user} signOut={signOut} />} />
                <Route path='/NewBotomation' element={<BotomationForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateBotomation' element={<BotomationForm currentUser={user} signOut={signOut} />} />
                <Route path='/Campaign' element={<Campaigns currentUser={user} signOut={signOut} />} />
                <Route path='/NewCampaign' element={<CampaignForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateCampaign' element={<CampaignForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateTemplate' element={<TemplateUpdate />} />
                <Route path='/' element={<Contacts currentUser={user} signOut={signOut} />} />
                <Route path='/Contacts' element={<Contacts currentUser={user} signOut={signOut} />} />
                <Route path='/settings' element={<Settings currentUser={user} signOut={signOut} />} />
                <Route path='/:contactphonenumber' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/chat/:contactphonenumber' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/:contactphonenumber/:name' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/chat/:contactphonenumber/:name' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/:contactphonenumber' element={<ChatPage currentUser={user} signOut={signOut} />} />
                <Route path='/NewMessageTemplate' element={<NewMessageTemplate currentUser={user} signOut={signOut} />} />
                <Route path='/NewFormFlow' element={<NewFormFlow currentUser={user} signOut={signOut} />} />
                <Route path='/FormFlowsGenerator' element={<FormFlowsGenerator currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateFormFlow' element={<FormFlowsGenerator currentUser={user} signOut={signOut} />} />

                </Routes>
            </div>
          </div>
        ) : (
          <>
            <NavbarMenu />
            <Routes>
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path='*' element={<HomePage setUser={setUser} setWebSocket={setWebSocket} />} />
              <Route path='/Login' element={<Login setUser={setUser} setWebSocket={setWebSocket} />} />
              <Route path='/FacebookLogin' element={<FacebookLogin />} />
            </Routes>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
