import React, { useState } from 'react';
import './NewFormFlows.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';

const NewFormFlow = ({ currentUser }) => {
  const [flowName, setFlowName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [endpointType, setEndpointType] = useState('Gambot'); // Endpoint type: Gambot or External
  const [endpointUrl, setEndpointUrl] = useState(''); // External endpoint URL
  const [sendAllAnswers, setSendAllAnswers] = useState('false'); // Send all answers: true or false
  const [writeAnswerEndpoint, setWriteAnswerEndpoint] = useState(''); // Write answer webhook URL
  const [getDataEndpoint, setGetDataEndpoint] = useState(''); // Get data webhook URL

  const categories = [
    'SIGN_UP', 'SIGN_IN', 'APPOINTMENT_BOOKING', 'LEAD_GENERATION',
    'CONTACT_US', 'CUSTOMER_SUPPORT', 'SURVEY', 'OTHER'
  ];

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleFlowNameChange = (event) => {
    setFlowName(event.target.value);
  };

  const handleEndpointTypeChange = (event) => {
    setEndpointType(event.target.value);
  };

  const handleEndpointUrlChange = (event) => {
    setEndpointUrl(event.target.value);
  };

  const handleSendAllAnswersChange = (event) => {
    setSendAllAnswers(event.target.value); // True or false from dropdown
  };

  const handleWriteAnswerEndpointChange = (event) => {
    setWriteAnswerEndpoint(event.target.value); // Write answer webhook URL
  };

  const handleGetDataEndpointChange = (event) => {
    setGetDataEndpoint(event.target.value); // Get data webhook URL
  };

  const mode = "Create";
  const queryParams = new URLSearchParams({
    mode,
    flowName,
    selectedCategory: selectedCategory?.value || '',
    endpointType,
    endpointUrl: endpointType === 'External' ? endpointUrl : '',
    sendAllAnswers,
    writeAnswerEndpoint,
    getDataEndpoint,
    organization: currentUser.organization
  }).toString();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted:', { flowName, selectedCategory, endpointType, endpointUrl, sendAllAnswers, writeAnswerEndpoint, getDataEndpoint });


    
  };
  const variables = '$variables';
  return (
    <div className="new-form-flow-container">
      <h1>New Form Flow</h1>
      <form className='new-form-flow-form' onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter form flow name..."
          value={flowName}
          onChange={handleFlowNameChange}
          className="form-flow-name-input"
        />
        <div className="new-form-flow-form-category-selection">
          <Select
            options={categories.map(category => ({ value: category, label: category }))}
            onChange={handleCategoryChange}
            placeholder="Select a category"
            value={selectedCategory}
          />
        </div>

        {/* Endpoint Type Selection */}
        <div className="new-form-flow-endpoint-type-selection">
          <label>Endpoint Type:</label>
          <select value={endpointType} onChange={handleEndpointTypeChange} className="form-flow-endpoint-type-select">
            <option value="Gambot">Gambot</option>
            <option value="External">External</option>
          </select>
        </div>

        {/* External Endpoint URL input */}
        {endpointType === 'External' && (
          <input
            type="text"
            placeholder="Enter external endpoint URL..."
            value={endpointUrl}
            onChange={handleEndpointUrlChange}
            className="form-flow-endpoint-input"
          />
        )}

        {/* Send All Answers on Flow Completion Dropdown */}
        <div className="new-form-flow-send-all-answers-dropdown">
          <label>Send all answers on flow completion:</label>
          <select value={sendAllAnswers} onChange={handleSendAllAnswersChange} className="form-flow-send-all-answers-select">
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        {/* Write Answer Webhook URL */}
        <div className="new-form-flow-write-answer-webhook">
          <label>Webhook Address To Write Answers:</label>
          <input
            type="text"
            placeholder="Enter webhook to write answers..."
            value={writeAnswerEndpoint}
            onChange={handleWriteAnswerEndpointChange}
            className="form-flow-webhook-input"
          />
        </div>

        {/* Get Data Webhook URL */}
        <div className="new-form-flow-get-data-webhook">
          <label>Webhook Address To Get Data For {variables}:</label>
          <input
            type="text"
            placeholder="Enter webhook to fetch data..."
            value={getDataEndpoint}
            onChange={handleGetDataEndpointChange}
            className="form-flow-webhook-input"
          />
        </div>

        <br />
        <div className="new-form-flow-action-buttons">
          <Link to="/FormFlows">
            <button className="new-form-flow-action-buttons-cancel" type="button">
              Cancel
            </button>
          </Link>
          <Link
            to={{
              pathname: "/FormFlowsGenerator",
              search: `?${queryParams}`,
            }}
          >
            <button className="new-form-flow-action-buttons-continue" type="submit">
              Continue
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NewFormFlow;
