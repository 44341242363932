import React, { useState, useEffect } from 'react';
import './FormParamContent.css'; // Ensure this is imported for styling
import TextAreaWithCounter from '../TextAreaWithCounter';
import { MdDelete } from "react-icons/md";
import Switch from 'react-switch'; // Import the react-switch component

const FormParamContent = ({ contentItem, onUpdateContent, onDeleteContent, siblingOptions, currentUser, onVariablesUpdate }) => {
  const [inputValue, setInputValue] = useState(contentItem.content || '');
  const [isRequired, setIsRequired] = useState(contentItem.isRequired || false);
  const [options, setOptions] = useState(contentItem.options || []);
  const [selectedOption, setSelectedOption] = useState(contentItem.type);
  const [sendOnComplate, setSendOnComplete] = useState(contentItem.sendOnComplate || false); // Added for send on change
  const [variables, setVariables] = useState([]); // Store detected variables
  
  useEffect(() => {
    setSelectedOption(contentItem.type);
    setInputValue(contentItem.content || '');
  }, [contentItem]);

  // Helper function to detect template strings (e.g., ${{name}})
  const detectVariables = (value) => {
    if (typeof value !== 'string') {
      // If value is not a string, return an empty array since we can't detect variables
      return [];
    }
    const variableMatches = value.match(/\${{([^}]+)}}/g);
    if (variableMatches) {
      return variableMatches.map(variable => variable.replace(/\${{|\}}/g, ''));
    }
    return [];
  };

  const updateVariablesList = (newDetectedVariables) => {
    const mergedVariables = Array.from(new Set([...variables, ...newDetectedVariables]));

    // Add newDetectedVariables to the already existing ones without overwriting
    setVariables(prevVariables => Array.from(new Set([...prevVariables, ...newDetectedVariables])));
    
    const screenVariables = {
      screenId: contentItem.id,
      variables: mergedVariables
    };

    // Pass the updated variables to the parent
    onVariablesUpdate(screenVariables); 
  };
  console.log('variables' + variables);

  
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    
    // Detect variables from input
    const detectedVariables = detectVariables(newValue);
    
    // Update the variable list
    updateVariablesList(detectedVariables);

    onUpdateContent(contentItem.id, { ...contentItem, content: newValue });
  };
  const [imageHeight, setImageHeight] = useState(contentItem.content?.height || 340); // Default to 340px if not set
  const handleHeightChange = (newHeight) => {
    if (!newHeight) return;

    setImageHeight(newHeight); // Update the local state of image height

    // Create updated content with the new height, keeping the existing src and formData
    const updatedContent = {
      ...contentItem.content, // Copy the existing content (src, formData)
      height: newHeight,      // Only update the height property
    };

    // Update the content item with the new height
    onUpdateContent(contentItem.id, { ...contentItem, content: updatedContent });
  };
  console.log(contentItem);
  const handleImageChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1]; // Extract the base64 part
          const updatedContent = {
            src: base64String,
            type: file.type,
            height: imageHeight, // Use the height from the state
            "scale-type": "contain"
          };


          // Create form data to send to the backend
          const formData = new FormData();
          formData.append('File', file);
          formData.append('Org', currentUser.organization); // Replace with your actual organization ID
          formData.append('file_type', file.type);
          formData.append('file_length', file.size);

          const thisFile = { formData: formData, src: updatedContent, height: imageHeight };
          onUpdateContent(contentItem.id, { ...contentItem, content: thisFile });
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };
  const handleToggleRequired = () => {
    setIsRequired(!isRequired);
    onUpdateContent(contentItem.id, { ...contentItem, isRequired: !isRequired });
  };

  const handleDelete = () => {
    onDeleteContent(contentItem.id);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? value : option
    );

    // Detect variables from options
    const detectedVariables = detectVariables(value);
    
    // Update the variable list
    updateVariablesList(detectedVariables);

    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };



  const handleAddOption = () => {
    const newOption = `Option ${options.length + 1}`;
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };

  const handleOptionTypeChange = (e) => {
    const newType = e.target.value;
    const selectedSiblingOption = siblingOptions.find(option => option.id === parseFloat(newType));

    if (selectedSiblingOption) {
      let updatedContent = contentItem.content;

      if (newType >= 1.1 && newType < 2) {
        updatedContent = contentItem.content || '';
      } else {
        updatedContent = '';
      }

      const updatedContentItem = {
        ...contentItem,
        type: parseFloat(newType),
        name: selectedSiblingOption.name,
        content: updatedContent,
        options: [],
      };

      setSelectedOption(newType);
      onUpdateContent(contentItem.id, updatedContentItem);
    }
  };
  const handleToggleSendOnChange = () => {
    setSendOnComplete(!sendOnComplate);
    onUpdateContent(contentItem.id, { ...contentItem, sendOnComplate: !sendOnComplate });
  };

  const renderInputField = () => {
    switch (parseFloat(selectedOption)) {
      case 1.1: // Large Heading
        return (
          <TextAreaWithCounter
            value={inputValue}
            onChange={handleInputChange}
            maxLength={80}
            placeholder="Enter large heading (max 80 characters)"
          />
        );
      case 1.2: // Small Heading
        return (
          <TextAreaWithCounter
            value={inputValue}
            onChange={handleInputChange}
            maxLength={80}
            placeholder="Enter small heading (max 80 characters)"
          />
        );
      case 1.3: // Caption
        return (
          <TextAreaWithCounter
            value={inputValue}
            onChange={handleInputChange}
            maxLength={4096}
            placeholder="Enter caption (max 4096 characters)"
          />
        );
      case 1.4: // Body
        return (
          <TextAreaWithCounter
            value={inputValue}
            onChange={handleInputChange}
            maxLength={4096}
            placeholder="Enter body text (max 4096 characters)"
          />
        );
      case 2.1: // Image
        return (
          <div className="form-param-content-inline-image-upload">
            <div className="form-param-content-image-input">
              <input
                type="file"
                id={`image-${contentItem.id}`}
                accept="image/*"
                onChange={handleImageChange}
                className="form-param-content-inline-image-input"
              />
            </div>
            <div className="form-param-content-image-height-container">
              <div className="form-param-content-image-height">
                <label htmlFor={`image-height-${contentItem.id}`} className="form-param-content-image-height-label">
                  Image height:
                </label>
                <input
                  type="number"
                  id={`image-height-${contentItem.id}`}
                  value={imageHeight}
                  onChange={(e) => handleHeightChange(e.target.value)}
                  className="form-param-content-inline-image-height-input"
                />
              </div>

              {/* File info below the row */}
              <div className="form-param-content-file-info">
                <span className="file-info-text">Maximum file size: 300kb</span><br />
                <span className="file-info-text">Acceptable file types: JPEG, PNG</span>
              </div>
            </div>
          </div>
        );
      case 3.1: // Short Answer
        return (
          <div className="form-param-content-short-answer-text-input-container">
            <div className="form-param-content-inline">
              <label htmlFor="shortAnswerLabel">Label</label>
              <input
                type="text"
                id="shortAnswerLabel"
                value={inputValue.label || ''}
                onChange={(e) => handleInputChange({ ...inputValue, label: e.target.value })}
                maxLength={20}
                placeholder="Enter label"
              />
              <div className="form-param-content-short-answer-text-char-count">{`${(inputValue.label || '').length}/20`}</div>
            </div>

            <div className="form-param-content-inline">
              <label htmlFor="shortAnswerInstruction">Instruction</label>
              <input
                type="text"
                id="shortAnswerInstruction"
                value={inputValue.instruction || ''}
                onChange={(e) => handleInputChange({ ...inputValue, instruction: e.target.value })}
                maxLength={80}
                placeholder="Enter instruction"
              />
              <div className="form-param-content-short-answer-text-char-count">{`${(inputValue.instruction || '').length}/80`}</div>
            </div>
          </div>
        );

      case 3.2: // Paragraph
        // return (
        //   <TextAreaWithCounter
        //     value={inputValue}
        //     onChange={handleInputChange}
        //     placeholder={`Enter ${contentItem.name.toLowerCase()}`}
        //     maxLength={600} // Example maxLength for short answer/paragraph, adjust as needed
        //   />
        // );
        return (
          <div className="form-param-content-paragraph-container">
            <div className="form-param-content-inline">
              <label htmlFor="form-param-content-paragraph">Label</label>
              <input
                type="text"
                id="form-param-content-paragraph"
                value={inputValue.label || ''}
                onChange={(e) => handleInputChange({ ...inputValue, label: e.target.value })}
                maxLength={20}
                placeholder="Enter label"
              />
              <div className="form-param-content-paragraph-char-count">{`${(inputValue.label || '').length}/20`}</div>
            </div>

            <div className="form-param-content-inline">
              <label htmlFor="form-param-content-paragraph-shortAnswerInstruction">Instruction</label>
              <input
                type="text"
                id="form-param-content-paragraph-shortAnswerInstruction"
                value={inputValue.instruction || ''}
                onChange={(e) => handleInputChange({ ...inputValue, instruction: e.target.value })}
                maxLength={80}
                placeholder="Enter instruction"
              />
              <div className="form-param-content-paragraph-char-count">{`${(inputValue.instruction || '').length}/80`}</div>
            </div>
          </div>
        );

      case 3.3: // Date Picker
        return (
          <input
            type="date"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            className="custom-date-input"
          />
        );

      case 4.1: // Single Choice
        return (
          <div>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Label"
              className="formParamContent-label"
            />
            <div className="formParamContent-options-container">
              <span>Options</span>
              {options.map((option, index) => (
                <div key={index} className="formParamContent-option-item">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    maxLength={30}
                    className="formParamContent-option-input"
                  />
                  <button
                    className="formParamContent-option-item-delete-button"
                    onClick={() => handleDeleteOption(index)}
                  >
                    <MdDelete className='formParamContent-delete-option-icon' size={20} color="#000" />
                  </button>
                </div>
              ))}
              <button className="formParamContent-add-option-button" onClick={handleAddOption}>
                <i className="fas fa-plus" /> Add option
              </button>
            </div>
          </div>
        );
      case 4.2: // Multiple Choice
        return (
          <div>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Label"
              className="formParamContent-label"
            />
            <div className="formParamContent-options-container">
              <span>Options</span>
              {options.map((option, index) => (
                <div key={index} className="formParamContent-option-item">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    maxLength={30}
                    className="formParamContent-option-input"
                  />
                  <button
                    className="formParamContent-option-item-delete-button"
                    onClick={() => handleDeleteOption(index)}
                  >
                    <MdDelete className='formParamContent-delete-option-icon' size={20} color="#000" />
                  </button>
                </div>
              ))}
              <button className="formParamContent-add-option-button" onClick={handleAddOption}>
                + Add Option
              </button>
            </div>
          </div>
        );
      case 4.3: // Dropdown
        return (
          <div className="formParamContent-dropdown-container">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Label"
              className="formParamContent-label"
            />
            <div className="formParamContent-options-container">
              <span>Options</span>
              {options.map((option, index) => (
                <div key={index} className="formParamContent-option-item">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    maxLength={30}
                    className="formParamContent-option-input"
                  />
                  <button
                    className="formParamContent-option-item-delete-button"
                    onClick={() => handleDeleteOption(index)}
                  >
                    <MdDelete className='formParamContent-delete-option-icon' size={20} color="#000" />
                  </button>
                </div>
              ))}
              <button className="formParamContent-add-option-button" onClick={handleAddOption}>
                + Add Option
              </button>
            </div>
          </div>
        );
      //  case 4.4: // Opt-in (Edit Mode)
      //   return (
      //     <div key={contentItem.id} className="FormParamContent-optin-container">
      //       <textarea
      //         value={inputValue}
      //         onChange={(e) => handleInputChange(e.target.value)}
      //         placeholder="Enter opt-in text"
      //         maxLength={300}
      //         className="FormParamContent-optin-textarea"
      //       />
      //       <p className="FormParamContent-optin-char-count">{`${inputValue.length}/300`}</p>
      //       <div className="FormParamContent-optin-link-container">
      //         <span className="FormParamContent-optin-link-label">Read more link · Optional</span>
      //         {!contentItem.readMoreLink ? (
      //           <button 
      //             className="FormParamContent-optin-add-link-button"
      //             onClick={() => onUpdateContent(contentItem.id, { ...contentItem, readMoreLink: true })}
      //           >
      //             Add "Read more" screen
      //           </button>
      //         ) : (
      //           <div className="FormParamContent-optin-link-edit-container">
      //             <input
      //               type="text"
      //               value={contentItem.readMoreText || ''}
      //               onChange={(e) => onUpdateContent(contentItem.id, { ...contentItem, readMoreText: e.target.value })}
      //               placeholder="Enter 'Read more' link text"
      //               className="FormParamContent-optin-link-input"
      //             />
      //             <button
      //               className="FormParamContent-optin-delete-link-button"
      //               onClick={() => onUpdateContent(contentItem.id, { ...contentItem, readMoreLink: false, readMoreText: '' })}
      //             >
      //               Delete link
      //             </button>
      //           </div>
      //         )}
      //       </div>
      //     </div>
      //   );

      default:
        return null;
    }
  };
  console.log('variables' + variables);
  return (
    <div className="form-param-content">
      <div className="form-param-header">
        <select value={selectedOption} onChange={handleOptionTypeChange}>
          {siblingOptions?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      {renderInputField()}

      <div className="form-param-footer">
        <div className="form-param-footer-left">
          {contentItem.type !== 1.1 && contentItem.type !== 1.2 && contentItem.type !== 1.3 && contentItem.type !== 1.4 && contentItem.type !== 2.1 && (
            <>
              <label className="form-param-required-label">
                Required
                <Switch
                  onChange={handleToggleRequired}
                  checked={isRequired}
                  onColor="#0b78e3"
                  offColor="#ccc"
                  className="react-switch"
                  height={20}
                  width={48}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </label>

              {/* Centering Send on Change toggle */}
              <label className="form-param-send-onchange-label">
                Send On Complate
                <Switch
                  onChange={handleToggleSendOnChange}
                  checked={sendOnComplate}
                  onColor="#0b78e3"
                  offColor="#ccc"
                  className="react-switch"
                  height={20}
                  width={48}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </label>
            </>
          )}
        </div>

        <button onClick={handleDelete} className="form-param-content-delete-button">
          <MdDelete size={24} color='#000' />
        </button>
      </div>

    </div>
  );
};

export default FormParamContent;
