import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmationDialog from '../ConfirmationDialog';
import './FormFlows.css';

const FormFlows = ({ currentUser }) => {
  const navigate = useNavigate();
  const [formFlows, setFormFlows] = useState([]); // State for form flows data
  const [openDialog, setOpenDialog] = useState(false); // State for delete confirmation dialog
  const [openPublishDialog, setOpenPublishDialog] = useState(false); // State for publish confirmation dialog
  const [selectedFlow, setSelectedFlow] = useState(null); // State for the selected form flow
  const [flowName, setFlowName] = useState(''); // State for the form flow name
  const [status, setStatus] = useState(''); // State for the form flow status
  const [flowType, setFlowType] = useState(''); // State for the form flow type

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser?.organization) {
        try {
          const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllFormFlows', {
            organization: currentUser.organization
          });
          console.log('Fetched Form Flows:', response.data);
          setFormFlows(response.data);
        } catch (error) {
          console.error('Error fetching form flows:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  const handlePublishFlowClick = (flow) => {
    setSelectedFlow(flow);
    setOpenPublishDialog(true);
  };

  const handlePublishConfirm = async () => {
    try {
      const payload = {
        organization: currentUser.organization,
        flowId: selectedFlow.flow.Id
      };
      const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/PublishFlow', payload);
      console.log(response);

      // Update the flow status to "Published" in the UI
      setFormFlows((prevFlows) =>
        prevFlows.map((flow) =>
          flow.flow.Id === selectedFlow.flow.Id ? { ...flow, status: 'Published' } : flow
        )
      );
    } catch (error) {
      console.error('Error publishing flow:', error);
    } finally {
      setOpenPublishDialog(false);
    }
  };

  const handleDeleteClick = (flow) => {
    setSelectedFlow(flow);
    setOpenDialog(true);
  };

  const handleEditClick = (flow) => {
    console.log(flow);
    const thisFlowQueryString = new URLSearchParams({
      organization: currentUser.organization,
      ...flow,
      components: JSON.stringify(flow.components)
    });
    navigate(`/UpdateFormFlow`, { state: { flow } }); // Use navigate with state to pass the entire flow object
    // navigate(`/UpdateFormFlow/?${thisFlowQueryString}`);
  };

  const handleConfirmDelete = () => {
    const payload = {
      organization: currentUser.organization,
      formFlowId: selectedFlow.flow.Id
    };

    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteFormFlow", payload)
      .then((response) => {
        console.log(response);
        setFormFlows(formFlows.filter(flow => flow.id !== selectedFlow.id));
      })
      .catch((error) => {
        console.error(error);
      });

    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  return (
    <div className="form-flow-list-container">
      <h1 className="form-flow-list-header">Form Flows</h1>
      <div className='form-flow-list-table-container'>
        <table className="form-flow-list-table">
          <thead>
            <tr>
              <th>Flow Name</th>
              <th>Flow ID</th>
              <th>Status</th>
              <th>Flow Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formFlows.map((flow) => (
              <tr key={flow.id}>
                <td>{flow.flowName}</td>
                <td>{flow.flow.Id}</td>
                <td>{flow.status}</td>
                <td>{flow.type}</td>
                <td>
                  <button className="form-flow-edit-button" onClick={() => handleEditClick(flow)}>Edit</button>
                  <button className="form-flow-delete-button" onClick={() => handleDeleteClick(flow)}>Delete</button>
                  <button className="form-flow-publish-button" onClick={() => handlePublishFlowClick(flow)}>Publish</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Dialog */}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete the flow: "${selectedFlow?.flowName}"?`}
        actionType="Delete"
      />

      {/* Publish Confirmation Dialog */}
      <ConfirmationDialog
        open={openPublishDialog}
        onClose={handleClosePublishDialog}
        onConfirm={handlePublishConfirm}
        message={`Are you sure you want to publish the flow: "${selectedFlow?.flowName}"?`}
        actionType='Publish'
      />

      <div className='form-flows-action-buttons'>
        <Link to="/NewFormFlow">
          <button className="add-new-form-flow-button">Add New Form Flow</button>
        </Link>
      </div>
    </div>
  );
};

export default FormFlows;
