import React from 'react';
import './FormFlowsPreview.css';

const FormFlowsPreview = ({ selectedScreen }) => {
  if (!selectedScreen) return null;

  const renderPreviewContent = (contentItem) => {
    const { label, instruction } = contentItem.content || {};
    console.log(contentItem);
    console.log('label', label);
    console.log('instruction', instruction);
    console.log(contentItem.content?.height);

    switch (contentItem.type) {
      case 1.1: // Large Heading
        return (
          <div key={contentItem.id}>
            <p style={{ fontWeight: 'bold', fontSize: '36px' }}>{contentItem.content}</p>
          </div>
        );
      case 1.2: // Small Heading
        return (
          <div key={contentItem.id}>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{contentItem.content}</p>
          </div>
        );
      case 1.3: // Caption
      case 1.4: // Body
        return (
          <div
            key={contentItem.id}
            style={{ fontSize: contentItem.type === 1.3 ? '12px' : '14px' }}
          >
            {contentItem.content}
          </div>
        );
      case 2.1: // Image
      return (
        <div key={contentItem.id} className='form-flows-preview-image-preview-container'>
          <img 
            src={`data:${contentItem?.content?.type};base64,${contentItem?.content?.src?.src}`} 
            alt={contentItem.name} 
            style={{ height: `${contentItem?.content?.height}px` }} 
            
            />
        </div>
      );
      case 3.1: // Short Answer
        return (
          <div key={contentItem.id} className="form-flows-preview-short-answer-preview-container">
            <input
              type="text"
              placeholder={contentItem?.content?.label !== "" ? contentItem?.content?.label : 'Label'}
              readOnly  
            />
            <p className="form-flows-preview-short-answer-preview-container-instruction-p">
              {contentItem.content.instruction}
            </p>
          </div>
        );
      case 3.2: // Paragraph
        return (
          <div key={contentItem.id} className="FormFlowsPreview-paragraph-preview-container">
            <textarea
              placeholder={contentItem.content.label}
              readOnly
              maxLength={600}
              rows={6} // Adjust the number of rows to make the box larger
            />
            <p className='FormFlowsPreview-paragraph-preview-container-instruction-p'>{contentItem.content.instruction}</p>
          </div>
        );
      case 3.3: // Date Picker
        return (
          <div key={contentItem.id} className='FormFlowsPreview-datePicker-preview-container'>
            <input type="date" readOnly />
          </div>
        );
      case 4.1: // Single Choice      
        return (
          <div key={contentItem.id} className='FormFlowsPreview-single-choice-preview-container'>
            <p className="FormFlowsPreview-single-choice-preview-container-title">{contentItem.content}</p>
            {contentItem?.options?.map((option, index) => (
              <div key={index} className="FormFlowsPreview-single-choice-preview-container-option-item">
                <label htmlFor={`option-${index}`} className="FormFlowsPreview-single-choice-preview-container-option-label">
                  <span className='FormFlowsPreview-single-choice-preview-container-option-label-span'>{option}</span>
                  <input
                    type="radio"
                    id={`option-${index}`}
                    name={`single-choice-${contentItem.id}`}
                    className="FormFlowsPreview-single-choice-preview-container-radio-input"
                  />
                </label>
              </div>
            ))}
          </div>
        );
        case 4.2: // Multiple Choice
        return (
          <div key={contentItem.id} className='FormFlowsPreview-multiple-choice-preview-container'>
            <p className="FormFlowsPreview-multiple-choice-preview-container-title">{contentItem.name}</p>
            {contentItem?.options?.map((option, index) => (
              <div key={index} className="FormFlowsPreview-multiple-choice-preview-container-option-item">
                <label htmlFor={`option-${index}`} className="FormFlowsPreview-multiple-choice-preview-container-option-label">
                  <span className='FormFlowsPreview-multiple-choice-preview-container-option-label-span'>{option}</span>
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={`multiple-choice-${contentItem.id}`}
                    className="FormFlowsPreview-multiple-choice-preview-container-checkbox-input"
                  />
                </label>
              </div>
            ))}
          </div>
        );
        case 4.3: // Dropdown
        return (
          <div key={contentItem.id} className="FormFlowsPreview-dropdown-preview-container">
            <label htmlFor={`dropdown-${contentItem.id}`} className="FormFlowsPreview-dropdown-preview-container-label">
              {contentItem.name}
            </label>
            <select 
              id={`dropdown-${contentItem.id}`} 
              name={`dropdown-${contentItem.id}`} 
              className="FormFlowsPreview-dropdown-preview-container-select" 
            >
              {contentItem?.options?.map((option, index) => (
                <option key={index} value={option} className="FormFlowsPreview-dropdown-preview-container-option">
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      
        // case 4.4: // Opt-in (Preview Mode)
        // return (
        //   <div key={contentItem.id} className="FormFlowsPreview-optin-preview-container">
        //     <label className="FormFlowsPreview-optin-preview-label">
        //       <input type="checkbox" className="FormFlowsPreview-optin-checkbox" />
        //       <span>{contentItem.content}</span>
        //       <a href="#" className="FormFlowsPreview-optin-read-more-link">Read more</a>
        //     </label>
        //   </div>
        // );
      default:
        return null;
    }
  };

  return (
    <div className="FormFlowsPreview-section">
      <h2 style={{ textAlign: 'center' }}>Preview</h2>
      <div className="FormFlowsPreview-content">
        <h3 style={{ textAlign: 'center' }}>{selectedScreen.title}</h3>
        {selectedScreen.content.map(renderPreviewContent)}
        <button className='FormFlowsPreview-section-continue-btn'>{selectedScreen.button}</button>
      </div>
    </div>
  );
};

export default FormFlowsPreview;
